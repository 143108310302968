import React, { useState, Fragment, useRef, useEffect } from "react";
import ReactAnime from "react-animejs";
import { useInViewport } from "react-in-viewport";

import styled from "styled-components";

const MainWrapp = styled.div`
  font-size: inherit;
  /* p,
  span {
    font-size: inherit;
  }
  p {
    display: flex;
    flex-wrap: wrap;
  } */
  span,
  div,
  p {
    font-size: inherit !important;
    color: inherit !important;

    display: flex !important;
    flex-wrap: wrap;
    line-height: inherit !important;
  }
`;

const Word = styled.span`
  overflow: hidden;
  display: inline-block !important;
  .letter {
    opacity: 0;
  }
`;

const Space = styled.span`
  height: 1rem;
`;

function IntroLetter({ char }) {
  const [hoverClass, setHoverClass] = useState("single-letter");
  return <span className="letter">{char}</span>;
}

function IntroWord({ word }) {
  return (
    <Word style={{ display: "inline-block" }}>
      {word.split("").map((letter, letterIndex) => (
        <IntroLetter index={letterIndex} char={letter} />
      ))}
      <Space>&nbsp;</Space>
    </Word>
  );
}

function IntroText({ children }) {
  return (
    <MainWrapp>
      <p className="intro-text">
        {children.split(" ").map((word, wordIndex) => (
          <Fragment>
            <IntroWord key={wordIndex} word={word} />
          </Fragment>
        ))}
      </p>
    </MainWrapp>
  );
}

const Animate = ({ text, long }) => {
  const { Anime, stagger } = ReactAnime;

  const myRef = useRef();
  const inViewport = useInViewport(myRef);

  // const [animSpeed, setAnimSpeed] = useState(1000);

  // const [animDel, setAnimDel] = useState(80);

  // const animSpeed = long ? 500 : 2500;
  // const animDel = long ? 25 : 80;

  // const animSpeed2 = 500;
  // const animDel2 = 25;

  const [animSpeed, setAnimSpeed] = useState(800);

  const [animDel, setAnimDel] = useState(50);

  return (
    <>
      <Anime
        initial={[
          {
            targets: ".letter",
            loop: false,
            autoplay: true,
            translateY: ["100%", 0],
            opacity: [0, 1],
            easing: "easeOutExpo",
            duration: 700,
            delay: (el, i) => 30 * i + 200,
          },
        ]}
      >
        <IntroText ref={myRef}>{text}</IntroText>
      </Anime>
      {/* {long ? (
        <Anime
          initial={[
            {
              targets: ".letter",
              loop: false,
              autoplay: true,
              translateY: [140, 0],
              opacity: 1,
              easing: "easeOutExpo",
              duration: 500,
              delay: (el, i) => 25 * i,
            },
          ]}
        >
          <IntroText ref={myRef}>{text}</IntroText>
          {long.toString()}
        </Anime>
      ) : (
        <Anime
          initial={[
            {
              targets: ".letter",
              loop: false,
              autoplay: true,
              translateY: [140, 0],
              opacity: 1,
              easing: "easeOutExpo",
              duration: 10000,
              delay: (el, i) => 80 * i,
            },
          ]}
        >
          <IntroText ref={myRef}>{text}</IntroText>
          {long.toString()}
        </Anime>
      )} */}
    </>
  );
};

export default Animate;
