import React, { useState, Fragment, useRef, useEffect } from "react";
import ReactAnime from "react-animejs";
import { useInViewport } from "react-in-viewport";

import styled from "styled-components";

const MainWrapp = styled.div`
  font-size: inherit;
  /* p,
  span {
    font-size: inherit;
  }
  p {
    display: flex;
    flex-wrap: wrap;
  } */
  span,
  div,
  p {
    font-size: inherit !important;
    color: inherit !important;
    display: flex;
    flex-wrap: wrap;
    line-height: inherit !important;
  }
`;

const Word = styled.span``;

const WordWrap = styled.span`
  overflow: hidden;
  display: inline-block;

  margin: 0;
`;

const Space = styled.span`
  height: 1rem;
  display: inline-block;
`;

function IntroWord({ word }) {
  return (
    <WordWrap>
      <Word className="word intro-text" style={{ display: "inline-block" }}>
        {word}&nbsp;
      </Word>
    </WordWrap>
  );
}

function IntroText({ children }) {
  const letters = ["a", "i", "o", "u", "w", "z", "A", "I", "O", "U", "W", "Z"];
  let letterPres = false;
  return (
    <MainWrapp>
      <p className="intro-text">
        {children.split(" ").map((word, wordIndex) => {
          if (letterPres) {
            letterPres = false;
          } else {
            const nextWord = children.split(" ")[wordIndex + 1];
            if (letters.includes(word)) {
              letterPres = true;
              return (
                <Fragment>
                  <IntroWord
                    key={wordIndex}
                    word={`${word}${"\u00A0"}${nextWord}`}
                  />
                </Fragment>
              );
            }
            return (
              <Fragment>
                <IntroWord key={wordIndex} word={word} />
              </Fragment>
            );
          }
        })}
      </p>
    </MainWrapp>
  );
}

const AnimateBody = ({ text, long }) => {
  const { Anime, stagger } = ReactAnime;

  const myRef = useRef();
  const inViewport = useInViewport(myRef);

  return (
    <>
      <Anime
        initial={[
          {
            targets: ".word",
            loop: false,
            autoplay: true,
            translateY: ["120%", 0],
            opacity: [0, 1],
            easing: "easeOutExpo",
            duration: 700,
            delay: (el, i) => 10 * i + 400,
          },
        ]}
      >
        <IntroText ref={myRef}>{text}</IntroText>
      </Anime>
    </>
  );
};

export default AnimateBody;
